<style lang="scss" scoped></style>
<template>
    <a-spin size="large" :spinning="spinning">
        <div class="consultingManagement">
            <ds-header title="服务配置管理"></ds-header>
            <div class="main">
                <div class="main-left" :style="{height:height}" style="margin-top: 15px;overflow-y: scroll;">
                    <p style="font-weight:700">所在项目</p>
                    <div>
                        <a-tree v-if="defaultExpandAll" :defaultSelectedKeys="defaultSelectedKeys" :tree-data="treeData"
                            :replace-fields="replaceFields" :defaultExpandAll="defaultExpandAll" @select="onSelect" />
                    </div>
                </div>
                <div class="main-right" style="margin-top: 10px">

                    <a-form class="formModel" layout="horizontal" :label-col="{ span: 3 }" :wrapper-col="{ span: 21 }">
                        <a-form-model-item style="z-index: 0;" label="展示文案">
                            <div style="color: red;">若文案中电话号码支持一键拨打，请用{}标注，例：{152****1234}。</div>
                            <div class="content" style="width: 800px;position: relative;">
                                <div id="editer"></div>
                                <!-- <div v-if="disabled" class="mengceng"></div> -->
                            </div>
                        </a-form-model-item>
                        <a-form-model-item v-if="disabled" :wrapper-col="{ span: 6, offset: 8 }">
                            <a-button type="primary" @click="disabled = false; editor.enable();"
                                style="margin-right: 20px"> 编辑
                            </a-button>
                        </a-form-model-item>
                        <a-form-model-item v-else :wrapper-col="{ span: 6, offset: 8 }">
                            <a-button type="primary" @click="handleAddOk" style="margin-right: 20px"> 保存 </a-button>
                            <a-button type="info" @click="disabled = true; editor.disable()"> 取消 </a-button>
                        </a-form-model-item>
                    </a-form>
                </div>
            </div>
        </div>
    </a-spin>
</template>

<script type="text/babel">
/**
 * TODO fileOverview
 * --------------
 * @author xiaoF
 * @since 2021/6/1.
 */
import * as api from "@/api/demandPool/index.js";
import wangEditor from "wangeditor";
import { IMG_API } from "@/config";
import moment from "moment";
import deletebut from "@/components/delete/index";

export default {
    name: "index",
    components: {
        deletebut
    },
    data() {
        return {
            title: '新增展示企业',
            parent_industry_id: null,//添加或修改的父级id
            activeKey: 1,
            search: {
                projectIdList: []
            },
            treeData: [],
            replaceFields: {
                children: 'child',
                title: 'project_name',
                key: 'id'
            },
            spinning: false,
            projectList: [],
            addShow: false,
            enterpriseName: '',
            EprojectList: [],// 新增企业
            add: {
                projectId: '',
                introduceText: '',
            },
            disabled: true,
            introduceText:'',
            defaultExpandAll:false,
            defaultSelectedKeys:[],
            height:''
        };
    },
    created() {
        // this.getList();
    },

    async mounted() {
        await this.createEditor()
        this.getproject();
        let handleResize = ()=> {
        this.height = window.innerHeight - 220+'px'
        }
        
    window.addEventListener('resize', handleResize);
    handleResize()
    },
    methods: {
        pageChange(pageOption) {
            this.pagination.current = pageOption.current
            this.pagination.pageSize = pageOption.pageSize
            this.getList()
        },
        getList(val) {
            if (val) {
                this.pagination.current = 1
                this.pagination.pageSize = 10
            }
            this.spinning = true
            api.IntroduceList({
                ...this.search,
                page: this.pagination.current,
                size: this.pagination.pageSize
            })
                .then((res) => {
                    this.spinning = false
                    console.log(res, '==========>');
                    if (res.code == '200') {
                        this.table.data = res.data.list;
                        this.pagination.total = res.data.total
                    } else {
                        this.$message.error(res.msg)
                    }
                });
        },
        handleDelMenu(record) {
            api.deleteIntroduce(record.id).then((res) => {
                if (res.code === "200") {
                    this.$message.success("删除成功");
                    this.getList();
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        async getproject() {
            let res = await api.getShunyiAllProject({ check_type: true })
            console.log(res, '=======>');
            this.projectList = res.data
            this.treeData = res.data
            this.defaultExpandAll = true
            if (this.projectList[0].child) {
                this.getInfo(this.projectList[0].child[0].id)
                this.add.projectId = this.projectList[0].child[0].id
                this.defaultSelectedKeys = [this.projectList[0].child[0].id]
            } else {
                this.getInfo(this.projectList[0].id)
                this.add.projectId = this.projectList[0].id
                this.defaultSelectedKeys = [this.projectList[0].id]
            }
        },
        // 树形结构选择
        async onSelect(selectedKeys, info) {
            this.add.projectId = selectedKeys[0]
            this.getInfo(selectedKeys)
        },
        // 创建富文本
        async createEditor() {
            const _this = this;
            const editor = new wangEditor(`#editer`);
            // 配置 onchange 回调函数，将数据同步到 vue 中
            editor.config.onchange = (newHtml) => {
                this.add.introduceText = newHtml;
            };
            // 隐藏全屏
            editor.config.showFullScreen = false
            editor.config.menus = [
                "fontSize",
                "foreColor",
                "backColor",
                "underline",
                "italic",
                "bold",
                "justify",
                "splitLine",
                "undo",
                "redo",
                "list",
                "table",
                "image",
                // "video",
            ];
            editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
            editor.config.uploadImgServer = this.IMG_API + "/oss/files";
            editor.config.uploadImgShowBase64 = false;
            editor.config.showLinkImg = false;
            editor.config.showLinkVideo = false;
            editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
            editor.config.uploadImgMaxSize = 10 * 1024 * 1024;
            editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
            editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
            // editor.config.uploadVideoAccept = ['mp4']
            editor.config.uploadFileName = "file";
            editor.config.uploadVideoName = "file";
            editor.config.uploadVideoTimeout = 60000;
            editor.config.uploadImgTimeout = 60000;
            editor.config.uploadVideoHooks = {
                // 上传视频之前
                before: function (xhr, editor, files) {
                    console.log(xhr);
                    console.log(editor);
                    console.log(files);
                },
                // 视频上传并返回了结果，视频插入已成功
                success: function (xhr) {
                    // this.$message.success('上传视频成功')
                },
                // 视频上传并返回了结果，但视频插入时出错了
                fail: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频出错，一般为 http 请求的错误
                error: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频超时
                timeout: function (xhr) {
                    _this.$message.error("上传请求超时");
                },
                // 视频上传并返回了结果，想要自己把视频插入到编辑器中
                // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
                customInsert: function (insertVideoFn, result) {
                    let videoHTML =
                        '&nbsp;<video poster="' +
                        result.redirect_uri +
                        '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
                        result.redirect_uri +
                        '" controls style="max-width:100%"></video>&nbsp;';
                    editor.cmd.do("insertHTML", videoHTML);
                    // result 即服务端返回的接口
                    // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                    // insertVideoFn(result.redirect_uri)
                },
            };
            editor.config.uploadImgHooks = {
                // 上传视频之前
                before: function (xhr) { },
                // 视频上传并返回了结果，视频插入已成功
                success: function (xhr) {
                    // this.$message.success('上传视频成功')
                },
                // 视频上传并返回了结果，但视频插入时出错了
                fail: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频出错，一般为 http 请求的错误
                error: function (xhr, editor, resData) {
                    _this.$message.error("上传请求失败");
                },
                // 上传视频超时
                timeout: function (xhr) {
                    _this.$message.error("上传请求超时");
                },
                // 视频上传并返回了结果，想要自己把视频插入到编辑器中
                // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
                customInsert: function (insertVideoFn, result) {
                    // result 即服务端返回的接口
                    // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                    insertVideoFn(result.redirect_uri);
                },
            };
            editor.config.customAlert = function (s, t) {
                switch (t) {
                    case "success":
                        _this.$message.success(s);
                        break;
                    case "info":
                        _this.$message.info(s);
                        break;
                    case "warning":
                        _this.$message.warning(s);
                        break;
                    case "error":
                        _this.$message.error(s);
                        break;
                    default:
                        _this.$message.info(s);
                        break;
                }
            };
            editor.config.placeholder = "请输入";
            // 创建编辑器
            editor.create();
            this.editor = editor;
        },
        handleAddOk(params) {
            let obj = {
                ...this.add
            }
            // obj.introduceText ? obj.introduceText = obj.introduceText.replace(/\{/g, "<span class='phone'>").replace(/\}/g, "</span>") : ''
            api.updateIntroduce({ ...obj, id: this.$route.query.id }).then((res) => {
                console.log(res, '==============>');
                if (res.code === "200") {
                    this.addShow = false;
                    this.$message.success("操作成功");
                    this.disabled = true;
                    this.editor.disable()
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getInfo(id) {
            api.IntroduceInfo(id).then(res => {
                console.log(res.data, '=============>');
                if (res.code == '200') {
                    let str = res.data.introduceText
                    this.editor.txt.html(str || '');
                    this.add.introduceText = str || ''
                    this.introduceText = str || ''
                    this.editor.disable();
                    this.disabled = true;
                } else {
                    this.editor.disable();
                    this.disabled = true;
                }

            })
        }
    },
};
</script>
<style scoped lang="scss">
.productName {
    display: -webkit-box;
    /* 需要对老版本的WebKit浏览器做兼容 */
    display: -ms-box;
    /* 对IE10的支持 */
    display: box;
    -webkit-line-clamp: 1;
    /* 设置显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置布局方向为垂直 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
}

.main {
    display: flex;
    justify-content: flex-start;
}

.main-left {
    width: 200px;
    height: 100%;
    border-right: 1px solid #f4f4f4;
}

.main-right {
    width: 69%;
    margin-left: 2%;
}
.mengceng{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #cccccc, $alpha: 0.1);
    z-index: 10000000;
}
</style>